import React from "react"
import "./style.css"
import { Col, Container, Row } from "react-bootstrap"

const Footer = () => {
  return (
    <footer>
        <Container>
          <Row className="footer-row">
            <Col md={3} sm={5} className='box'>
              <div className="logo">
                  <ion-icon name="bag"></ion-icon>
                  <h1>Umbreen Boutique</h1>
              </div>
              <p>Elegance Redefined, Style Perfected – Discover Your Unique Look with Umbreen Boutique.</p>
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>Locations</h2>
              <ul>
                <li></li>
                <li>Gulshan-e-Iqbal 13-D Karachi</li>
                <li>Model Town Lahore</li>
                <li>Thatha</li>
              </ul>
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>Contact Us</h2>
              <ul>
                <li> </li>
                <li>Email: umbreen@gmail.com</li>
                <li>Phone: +11923327586743</li>
              </ul>
            </Col>
          </Row>
        </Container>
    </footer>
  )
}

export default Footer
